import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import Banner from "../../layout/component/banner";
import Support from "./features/support";
import Callus from "./features/callus";
import SendEmail from "./features/send-email";
import { useLocation } from "react-router-dom";
import { useLazyGetCmsQuery } from "../../services/cms";
import Loader from "../../constants/Loader";
import { showError } from "../../constants/toast";

const ContactUs = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [getCmsData] = useLazyGetCmsQuery();
  const [cmsData, setCmsData] = useState<any>();

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const res = await getCmsData({ role: 1 }).unwrap();
      if (res?.statusCode === 200) {
        setCmsData(res?.data[0]);
      }
      setIsLoading(false)
    } catch (error: any) {
      showError(error?.data?.message || "Something went wrong")
      setIsLoading(false);
    }
  }

 


  useEffect(() => {
    fetchData();
  }, [])

  const bannerData = {
    videoSrc: "./assets/images/contact.mp4",
    heading: "Contact Us",
    subheading: "",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    showButton: false,
  };
  return (
    <Layout>
      <Loader isLoad={isLoading} />
      <Banner
        videoSrc={bannerData?.videoSrc}
        heading={bannerData?.heading}
        subheading={bannerData?.subheading}
        description={bannerData?.description}
        showButton={bannerData?.showButton}
      />
      <Support />
      <Callus data={cmsData} />
      <SendEmail data={cmsData} />
    </Layout>
  );
};

export default ContactUs;
