import { Button, fabClasses, TextField } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Cross from "../../../../layout/component/cross-icon";
import { useState } from "react";
import { usePostProfileSetupMutation } from "../../../../services/auth";
import { useFormik } from "formik";
import * as Yup from "yup";
import { generateEncryptedKeyBody } from "../../../../utils/crypto";
import { CommonBody } from "../../../../types/General";
import { showError, showToast, showWarning } from "../../../../constants/toast";
import { getToken, setCredentials } from "../../../../reducers/authSlice";
import { getFromStorage } from "../../../../constants/storage";
import { STORAGE_KEYS } from "../../../../constants/storageKeys";
import { useDispatch } from "react-redux";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import { UploadMedia } from "../../../../utils/mediaUpload";
import { isNumber, isString } from "../../../../utils/validations";
import moment from "moment";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import Loader from "../../../../constants/Loader";
import { useAppSelector } from "../../../../hooks/store";
import DatePicker from "react-datepicker";

type Body = {
  email: string;
  firstName: string;
  lastName?: string;
  dateOfBirth: string;
  companySize: any;
  companyName: string;
  phoneNo: string;
  dialCode: string;
  annotation: number;
  image?: string;
  countryName: string;
}

const ProfileForm = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [updateProfile] = usePostProfileSetupMutation();
  const dispatch = useDispatch();
  const token = getFromStorage(STORAGE_KEYS?.token);
  console.log(token, "token for profile");
  const [load, setLoad] = useState<boolean>(false);
  const [gender, setGender] = useState<number>(2);
  const location = useLocation();
  const { state } = location;
  const tokenFromStorage = getFromStorage(STORAGE_KEYS.token)
  const tokenFromReducer = useAppSelector(getToken)
  console.log(tokenFromStorage, "tokenFromStorage");
  console.log(tokenFromReducer, "tokenFromReducer");



  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";
    if (file) {
      if (file.type.startsWith("image/")) {
        console.log(file, "FILE");
        // setImage(URL.createObjectURL(file));
        setLoad((prev) => !prev);
        formik.setFieldValue("image", URL.createObjectURL(file));
        const res = await UploadMedia(file);
        if (res?.statusCode === 200) {
          formik.setFieldValue("image", res?.data?.image);
          showToast("Image uploaded successfully")
          setLoad((prev) => !prev);
        }
        else {
          showError(res?.message);
          setLoad((prev) => !prev);
        }
      } else {
        showError("Failed to upload image");
        setLoad((prev) => !prev);
      }
    }
  };
  console.log(state, "state in profile");


  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: "",
      lastName: "",
      email: state?.email,
      dateOfBirth: "",
      companySize: "",
      companyName: "",
      phoneNo: "",
      dialCode: "",
      gender: "",
      image: "",
      countryName: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .required("Name is required")
        .min(2, "Minimum 2 characters are required")
        .max(100, "Maximum 100 characters is required")
        .matches(
          /^[A-Za-z\s'-]+$/,
          "Name must contain only alphabets"
        ),
      lastName: Yup.string()
        .min(2, "Minimum 2 characters are required")
        .max(50, "Maximum 50 characters is required")
        .matches(
          /^[A-Za-z\s'-]+$/,
          "Name must contain only alphabets"
        ),
      phoneNo: Yup.string()
        .required("Phone number is required")
        .min(6, "Minimum 6 digits are required")
        .max(16, "Maximum 16 digits are required"),
      dialCode: Yup.string().required("Country code is required"),
      companyName: Yup.string()
        .required("Company name is required")
        .min(2, "Minimum 2 characters are required")
        .max(50, "Maximum 50 characters is required")
        .matches(
          /^[A-Za-z\s'-]+$/,
          "Company Name must contain only alphabets"
        ),
      dateOfBirth: Yup.string()
        .required("Date of birth is required"),
      companySize: Yup.number()
        .required("Company size is required")
        .typeError("Company size must be a number"),

    }),

    onSubmit: async (values) => {
      formik.setSubmitting(true);

      if (!gender) {
        showWarning("Please select a gender")
        return;
      }


      let body: Body = {
        email: state?.email,
        firstName: values.firstName,
        dateOfBirth: values.dateOfBirth,
        companySize: values.companySize,
        companyName: values.companyName,
        phoneNo: values.phoneNo,
        dialCode: values.dialCode,
        annotation: gender,
        countryName: values.countryName,
      };
      if (values.lastName) {
        body.lastName = values.lastName
      }
      if (values.image) {
        body.image = values.image;
      }
      console.log("body for setup", body);

      try {
        setIsLoading(true)
        let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
        const response = await updateProfile(encryptedBody).unwrap();
        if (response?.statusCode === 200) {
          showToast("Profile setup successfully")
          navigate("/document-upload", { state: "corporate" })
          dispatch(
            setCredentials({
              user: response?.data || null,
              token: token || null,
            }),
          );
        }
        setIsLoading(false)
      } catch (errors: any) {
        console.log(errors, "errrr");
        setIsLoading(false)
        showError(errors?.data?.message);
      }
    },
  });

  console.log("formik image", formik.values.image);


  const handleClick = (newGender: number) => {
    setGender(newGender);
    console.log(`Gender set to: ${newGender}`);
  };

  const handleChangePhone = (phone: any, country: any) => {
    formik.setFieldValue("phoneNo", phone?.replace(country.dialCode, ""));
    formik.setFieldValue(
      "dialCode",
      country?.dialCode.includes("+") ? "" : "+" + country?.dialCode
    );
    formik.setFieldValue("countryName", country?.name.toUpperCase());
  };

  return (
    <>
      <div className="login_rhs w_50">
        <Loader isLoad={isLoading} />
        <div className="login-box">
          <div className="login-heading">
            <h2 className="heading-font">Profile Setup</h2>
            <p>Login to Continue</p>
          </div>
          <form className="form" onSubmit={formik.handleSubmit}>
            {formik.values.image ? (
              <div className="upload-profile">
                <figure className={`${load ? "loading" : ""}`}>
                  <img className={formik.values.image ? "cover_img" : ""} src={formik.values.image} alt="user"></img>
                </figure>
                <div className="action">
                  <CancelIcon
                    onClick={() => {
                      formik.setFieldValue("image", "");
                    }}
                  />
                </div>
              </div>
            ) : (
              <div className="upload-profile">
                <figure >
                  <img src="./assets/images/user.svg" alt="icon" />
                  <input
                    placeholder="img"
                    type="file"
                    title="image"
                    accept=".png, .jpg, .jpeg"
                    onChange={handleImageUpload}
                  />
                </figure>
                <div className="action">
                  <AddIcon />
                  <input
                    placeholder="img"
                    type="file"
                    title="image"
                    accept=".png, .jpg, .jpeg"
                    onChange={handleImageUpload}
                  />
                </div>
              </div>
            )}

            <ul className="mrs_box">
              <li
                // className={gender ? "disabled" : ""}
                onClick={() => handleClick(2)}
                style={gender === 2 ? { color: "white", background: "black" } : {}}
              >
                MRS
              </li>
              <li
                // className={gender ? "disabled" : ""}
                onClick={() => handleClick(3)}
                style={gender === 3 ? { color: "white", background: "black" } : {}}
              >
                MISS
              </li>
              <li
                // className={gender ? "disabled" : ""}
                onClick={() => handleClick(1)}
                style={gender === 1 ? { color: "white", background: "black" } : {}}
              >
                MR
              </li>
            </ul>
            <div className="control_group">
              <label>First name</label>
              <TextField
                hiddenLabel
                placeholder="Enter first Name"
                fullWidth
                className="text_field"
                inputProps={{ maxLength: 50 }}
                name="firstName"
                onChange={(val) => {
                  if (
                    val.target.value === " " ||
                    val.target.value === "."
                  ) {
                  } else if (isString(val.target.value)) {
                    formik.handleChange(val);
                  }
                }}
                onBlur={formik.handleBlur}
                value={formik.values.firstName}
                helperText={
                  formik.touched.firstName && formik.errors.firstName
                }
              />
            </div>
            <div className="control_group">
              <label>Last name</label>
              <TextField
                hiddenLabel
                placeholder="Enter last Name"
                fullWidth
                className="text_field"
                inputProps={{ maxLength: 50 }}
                name="lastName"
                onChange={(val) => {
                  if (
                    val.target.value === " " ||
                    val.target.value === "."
                  ) {
                  } else if (isString(val.target.value)) {
                    formik.handleChange(val);
                  }
                }}
                onBlur={formik.handleBlur}
                value={formik.values.lastName}
                helperText={
                  formik.touched.lastName && formik.errors.lastName
                } />
            </div>
            <div className="control_group">
              <label>Email</label>
              <TextField
                hiddenLabel
                placeholder="Enter your email"
                fullWidth
                className="text_field"
                disabled
                name="email"
                value={state?.email}
                onChange={(val) => {
                  if (
                    val.target.value === " " ||
                    val.target.value === "."
                  ) {
                  } else {
                    formik.handleChange(val);
                  }
                }}
                onBlur={formik.handleBlur}

              />
            </div>
            <div className="control_group">
              <label>Date of Birth</label>
              <TextField
                hiddenLabel
                placeholder="D.O.B"
                fullWidth
                name="dateOfBirth"
                className="text_field"
                type="date"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.dateOfBirth}
                helperText={formik.touched.dateOfBirth && formik.errors.dateOfBirth}
                inputProps={{
                  // max: new Date().toISOString().split("T")[0],
                  max: moment().subtract(18, "years").format("YYYY-MM-DD"),
                }}
                onKeyDown={(e) => e.preventDefault()}
                InputProps={{ disableUnderline: true }}
              />
            </div>
            <div className="control_group">
              <label>Phone Number</label>
              <PhoneInput
                country={"us"}
                placeholder="Phone number"
                inputStyle={{ width: "100%" }}
                onChange={(phone, country) =>
                  handleChangePhone(phone, country)
                }
                onBlur={formik.handleBlur}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault(); // Prevent default behavior (form submission)
                    formik.handleSubmit(); // Manually submit the form
                  }
                }}
                value={formik.values.dialCode + formik.values.phoneNo}
              />
              {formik.touched.phoneNo && formik.errors.phoneNo ? (
                <h6 className="err_msg">
                  {formik.touched.phoneNo && formik.errors.phoneNo}
                </h6>
              ) : (
                ""
              )}
            </div>
            <div className="control_group">
              <label>Company size</label>
              <TextField
                hiddenLabel
                placeholder="Enter your Company Size"
                fullWidth
                className="text_field"
                name="companySize"
                onChange={(e) => {
                  if (isNumber(e.target.value)) {
                    formik.handleChange(e);
                  }
                }}
                inputProps={{ maxLength: 10 }}
                onBlur={formik.handleBlur}
                value={formik.values.companySize}
                helperText={formik.touched.companySize && formik.errors.companySize}
              />
            </div>
            <div className="control_group">
              <label>Company name</label>
              <TextField
                hiddenLabel
                placeholder="Enter your Company Name"
                inputProps={{ maxLength: 50 }}
                fullWidth
                name="companyName"
                className="text_field"
                onChange={(e) => {
                  if (isString(e.target.value)) {
                    formik.handleChange(e);
                  }
                }}
                onBlur={formik.handleBlur}
                value={formik.values.companyName}
                helperText={formik.touched.companyName && formik.errors.companyName}
              />
            </div>
            <div className="form_btn text_center">
              <Button
                className="btnn btn_primary big_cursor"
                type="submit"
              // onClick={() => navigate("/document-upload")}
              >
                Submit
              </Button>
            </div>
          </form >

          <Cross />
        </div >
      </div >
    </>
  );
};

export default ProfileForm;
